import { BusinessDetails, BusinessMetaContent} from "./businessDetails.types";


export const BUSINESS_NAME = 'Clean Away Sydney'

export const businessDetails : BusinessDetails = {
    businessName: BUSINESS_NAME,
    headerLogoUrl: '/assets/images/clean-away-sydney-full-logo.svg',
    instaHandle: '',
    facebookHandle: '',
    youtubeHandle: '',
    licenseNumber: '5104661',
    contactNumber: '0405 194 481',
    contactEmail: 'info@cleanawaysydney.com.au',
    websiteURL:'cleanawaysydney.com.au',
};

export const businessMetaContent : BusinessMetaContent = {
    homeDescription : [''],
    contactDescription : [''],
    aboutDescription : [''],
}

