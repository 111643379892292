import React from 'react'
import {useImage} from 'react-image'
import {Skeleton} from "@mui/material";
import './ImageLoader.scss';

type Props = {
    imgSrc: string;
    title?: string;
    alt?: string;
    height?: string;
    width?: string;
    isBackground?: boolean;
};
const ImageLoader =({ imgSrc, title, alt, height = '280px', width= '100%', isBackground } : Props) => {

    const {src, isLoading} = useImage({
        srcList: imgSrc,
        useSuspense: false,
    })

    return isLoading ?
        <Skeleton style={{transform: 'none'}} height={height} width={width}/>
        : isBackground ? <div className='imgBackground' style={{height, width, backgroundImage: `url(${src})`}} ></div> : <img src={src} title={title} alt={alt}/>
}

export default ImageLoader