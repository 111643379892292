import { BusinessColors } from "./index";
import styles from '../styles/_colours.module.scss';
const {
    darkPrimary,
    darkSecondary,
    lightPrimary,
    lightSecondary,
    blackPrimary,
    blackSecondary,
    whitePrimary,
    whiteSecondary
} = styles;

export const businessColors : BusinessColors = {
    dark:{
        primary:darkPrimary,
        secondary:darkSecondary,
    },
    light:{
        primary: lightPrimary,
        secondary: lightSecondary,
    },
    black :{
        primary :blackPrimary,
        secondary :blackSecondary,
    },
    white :{
        primary :whitePrimary,
        secondary :whiteSecondary,
    }
}
