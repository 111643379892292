import React from 'react';
import {FeatureTileInputType} from './FeatureTile.types'
import './FeatureTile.scss';
import ImageLoader from "../../atoms/image-loader/ImageLoader";
import {CategoryPoint} from "../../../content";

const FeatureTile = ({title, categoryImages, categoryPoints,icon} : FeatureTileInputType) =>{



    return (
        <div className='feature-tile'>
            <div className='tile-header'>
                <div>{icon}</div>
                <h2>{title}</h2>
            </div>
            <div className='tile-content'>
                {categoryImages?.length && <ImageLoader imgSrc={categoryImages[0].url} title={title} alt={title} isBackground />}
                <div className="points">
                    {categoryPoints?.map(({ id,title, icon} : CategoryPoint) => <div key={id} className='categoryPoint'>
                        {icon && icon}
                        <h4>{title}</h4>
                    </div>)}
                </div>
            </div>

        </div >
    )
}

export default FeatureTile;
