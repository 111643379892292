import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import type {} from '@redux-devtools/extension' // required for devtools typing
import {
    businessColors,
    businessDetails,
    businessMetaContent,
    BusinessDetails,
    BusinessMetaContent, BusinessColors, MainCategory
} from "../../content";
import {getFeatureTiles} from "./useBusinessStore.utils";

interface BusinessState {
    businessDetails: BusinessDetails;
    businessMetaContent: BusinessMetaContent;
    businessColors: BusinessColors;
    businessCategories: MainCategory[];

}const useBusinessStore = create<BusinessState>()(
    devtools(
        // todo enable persist when complete
        // persist(
            (set) => ({
                businessDetails: businessDetails,
                businessMetaContent: businessMetaContent,
                businessColors: businessColors,
                businessCategories: getFeatureTiles(),
            }),
            {
                name: 'businessStore',
            },
        // ),
    ),
)
export default useBusinessStore;
