import React from "react";
import './Footer.scss';
import useBusinessStore from "../../../stores/businessStore/useBusinessStore";

const Footer = () =>{
    const {businessDetails :{headerLogoUrl, businessName, licenseNumber} } = useBusinessStore();
    const date = new Date()
    return(<footer className='app-footer'>
        <div>
            <div>
                <div>

                </div>
                <div>

                </div>
                <div>

                </div>
                <div>
                    <img src={headerLogoUrl} title={businessName} alt={businessName} />
                </div>
            </div>
        </div>
        <div>
            <div>
                <div>
                    License: {licenseNumber}
                </div>
                <div>
                    ©{businessName} {date.getFullYear()}
                </div>
            </div>
        </div>
    </footer>)
}

export default Footer;
