import React from 'react';
import './App.scss';
import Header from "./components/organisms/header/Header";
import Footer from "./components/organisms/footer/Footer";
import TilesContainer from "./components/organisms/tiles-container/TilesContainer";

function App() {

  return (
    <div className="App">
        <Header />
          <article>
              <TilesContainer />
          </article>
          <Footer />
    </div>
  );
}

export default App;
