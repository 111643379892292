import {CategoryImage, CategoryPoint, MainCategory} from "./index";
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import PestControlIcon from '@mui/icons-material/PestControl';
import StoreIcon from '@mui/icons-material/Store';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WaterDamageIcon from '@mui/icons-material/WaterDamage';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import PestControlRodentIcon from '@mui/icons-material/PestControlRodent';
import DeleteIcon from '@mui/icons-material/Delete';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import BugReportIcon from '@mui/icons-material/BugReport';
import WavesIcon from '@mui/icons-material/Waves';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import BathroomIcon from '@mui/icons-material/Bathroom';
import BuildIcon from '@mui/icons-material/Build';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import WindowIcon from '@mui/icons-material/Window';
import FlutterDashIcon from '@mui/icons-material/FlutterDash';

import {BUSINESS_NAME} from "./businessDetails";

const endOfLeaseId : MainCategory['id'] = 1
const pestControlId: MainCategory['id'] = 2
const commercialId: MainCategory['id'] = 3
const residentialId: MainCategory['id'] = 4
const birdControlId: MainCategory['id'] = 5
const propertyMaintenanceId: MainCategory['id'] = 6

export const mainCategories : MainCategory[] = [
    {id: endOfLeaseId, order: 1, title: 'End of lease cleaning', icon: <OtherHousesIcon />},
    {id: pestControlId, order: 1, title: 'Pest control', icon: <PestControlIcon />},
    {id: commercialId, order: 1, title: 'Commercial cleaning', icon: <StoreIcon />},
    {id: residentialId, order: 1, title: 'Residential cleaning', icon: <FamilyRestroomIcon />},
    // {id: birdControlId, order: 1, title: 'Bird control', icon: <FlutterDashIcon />},
    {id: propertyMaintenanceId, order: 1, title: 'Property maintenance', icon: <WaterDamageIcon />},
]

//id groups
const generalCleaningIds =[endOfLeaseId, commercialId, residentialId, propertyMaintenanceId]

export const categoryPoints : CategoryPoint[] = [
    {id: 1, categoryIds: [...generalCleaningIds],  title: 'Deep cleaning', icon: <CoronavirusIcon />},
    {id: 2, categoryIds: [...generalCleaningIds],  title: 'Pest control', icon: <PestControlIcon />},
    {id: 3, categoryIds: [...generalCleaningIds],  title: 'Rubbish removal', icon: <DeleteIcon />},
    {id: 4, categoryIds: [pestControlId,birdControlId],  title: 'Bird spikes', icon: <FlutterDashIcon />},
    {id: 5, categoryIds: [propertyMaintenanceId],  title: 'Home and office cleaning', icon: <MapsHomeWorkIcon />},
    {id: 6, categoryIds: [pestControlId],  title: 'Termite pest management', icon: <BugReportIcon />},
    {id: 6, categoryIds: [pestControlId],  title: 'Rodent pest management', icon: <PestControlRodentIcon />},
    {id: 7, categoryIds: [...generalCleaningIds],  title: 'High pressure cleaning', icon: <WavesIcon />},
    {id: 13, categoryIds: [...generalCleaningIds],  title: 'Carpet cleaning', icon:<CleaningServicesIcon />},
    {id: 8, categoryIds: [...generalCleaningIds],  title: 'Kitchen and bathroom cleaning', icon: <BathroomIcon />},
    {id: 9, categoryIds: [...generalCleaningIds],  title: 'Blind cleaning and repairs', icon: <BuildIcon />},
    {id: 10, categoryIds: [...generalCleaningIds],  title: 'Strata maintenance', icon: <ApartmentIcon />},
    {id: 11, categoryIds: [residentialId,propertyMaintenanceId],  title: 'NDIS', icon: <AccessibleForwardIcon />},
    {id: 12, categoryIds: [...generalCleaningIds],  title: 'Window cleaning', icon: <WindowIcon />},
]


export const categoryImages : CategoryImage[] = [
    {
        meta:'Main in white suit and mask cleaning desk with pump',
        alt:'Deep cleaning',
        title: 'Deep cleaning',
        description:`${BUSINESS_NAME} performing deep cleaning for most applications`,
        url:'https://onedrive.live.com/embed?resid=8C2806D9924F311B%21122756&authkey=%21AMXPz0WKX2_AwIU&width=840&height=600',
        categoryIds:[commercialId],
    },
    {
        meta:'Sponge cleaning dirty white tiles',
        alt:'Kitchen and bathroom cleaning',
        title: 'Kitchen and bathroom cleaning',
        description:`${BUSINESS_NAME} performing kitchen and bathroom cleaning`,
        url:'https://onedrive.live.com/embed?resid=8C2806D9924F311B%2113018&authkey=%21AD6zk2AF_1AnSws&width=424&height=283',
        categoryIds:[residentialId],
    },
    {
        meta:'Modern home front',
        alt:'End of lease cleaning',
        title: 'End of lease cleaning',
        description:`${BUSINESS_NAME} end of lease cleaning`,
        url:'https://onedrive.live.com/embed?resid=7F9C776127614AAE%2132913&authkey=%21AG8_42uUu0b4IC8&width=660',
        categoryIds:[endOfLeaseId],
    },{
        meta:'Pest control man',
        alt:'Pest control services Sydney',
        title: 'Pest control services Sydney',
        description:`${BUSINESS_NAME} pest control`,
        url:'https://onedrive.live.com/embed?resid=7F9C776127614AAE%2132979&authkey=%21AN2k3gNGvdXrA5E&width=660',
        categoryIds:[pestControlId],
    },{
        meta:'Highrise buildings',
        alt:'Cleaning property maintenance',
        title: 'Property maintenance',
        description:`${BUSINESS_NAME} property maintenance`,
        url:'https://onedrive.live.com/embed?resid=7F9C776127614AAE%21197&authkey=%21ANVm14Fq5wpgSy0&width=660',
        categoryIds:[propertyMaintenanceId],
    },{
        meta:'Highrise buildings',
        alt:'Cleaning property maintenance',
        title: 'Property maintenance',
        description:`${BUSINESS_NAME} property maintenance`,
        url:'https://onedrive.live.com/embed?resid=7F9C776127614AAE%21197&authkey=%21ANVm14Fq5wpgSy0&width=660',
        categoryIds:[propertyMaintenanceId],
    }
]

