import _ from 'lodash'
import {mainCategories, categoryPoints, categoryImages} from '../../content'
export const getFeatureTiles=() =>{
    const featureTiles = mainCategories.map((c)=>{
        categoryPoints.map((cp)=>{
            cp.categoryIds.map((cpId)=>
                cpId === c.id ? c.categoryPoints = [...c.categoryPoints || [], cp] : c.categoryPoints)
        })
        categoryImages.map((ci)=>{
            ci?.categoryIds?.length && ci?.categoryIds.map((ciId)=>
                ciId === c.id ? c.categoryImages = [...c.categoryImages || [], ci] : c.categoryPoints)
        })
        return ({...c, categoryPoints: _.uniq(c.categoryPoints)});
    })
    return featureTiles
}
