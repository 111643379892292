import React from "react";
import useBusinessStore from "../../../stores/businessStore/useBusinessStore";
import './Header.scss';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const Header = () =>{
    const {businessDetails :{headerLogoUrl, businessName, contactNumber, contactEmail} } = useBusinessStore();
    return (
        <header className="app-header">
            <div>
                <img src={headerLogoUrl} title={businessName} alt={businessName} />
                <div className="contact">
                    <div>
                        <PhoneIcon />
                        <a href={contactNumber}><h5>{contactNumber}</h5></a>
                    </div>
                    <div>
                        <EmailIcon />
                        <a href={`mailto:“${contactEmail}”`}><h5>{contactEmail}</h5></a>
                    </div>

                </div>
            </div>
        </header>
    )
}

export default Header;
