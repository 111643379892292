import {MainCategory} from "../../../content";
import FeatureTile from "../../molecule/feature-tile/FeatureTile";
import React from "react";
import useBusinessStore from "../../../stores/businessStore/useBusinessStore";
import './TilesContainer.scss';

const TilesContainer = () => {
    const { businessCategories } = useBusinessStore()
    return(
        <div className='feature-tiles-container'>
            <div>
                {businessCategories.map((businessCategory : MainCategory) =>
                    (<div key={businessCategory.id}>
                        <FeatureTile
                            {...businessCategory}
                        />
                    </div>))}
            </div>
        </div>
    )
}

export default TilesContainer
